import axios from "axios";
import { UtilHelper } from "./UtilHelper";

export type CRLV = {
  renavam: string;
  placa: string;
  anoExercicio: number;
  anoFabricacao: number;
  anoModelo: number;
  potencia: number;
  cilindrada: number;
  pbt: number;
  cmt: number;
  eixos: number;
  carroceria: string;
  proprietario: string;
  numeroDocumentoProprietario: string;
  observacao: string;
  cidade: string;
  estado: string;
  especie: string;
  tipo: string;
  marca: string;
  modelo: string;
  cor: string;
  chassi: string;
};

// Cria uma nova instância só para essa funcionalidade
const functionApi = axios.create({
  baseURL: "https://aetmasterfunction.azurewebsites.net/api",
  timeout: 10000, // opcional: tempo limite em ms
  headers: {},
});

export const VeiculoHelper = {
  ValidarPlaca(placa) {
    if (!placa || typeof placa !== "string") {
      return false;
    }

    placa = placa.toUpperCase().replace("-", "").trim();

    if (placa.length !== 7) {
      return false;
    }

    // Regex que valida tanto o modelo antigo quanto Mercosul
    const regex = /^[A-Z]{3}[0-9][A-Z0-9][0-9]{2}$/;

    return regex.test(placa);
  },

  ValidarChassi(chassi) {
    if (!chassi || typeof chassi !== "string") {
      return false;
    }

    chassi = chassi.toUpperCase().trim();
    if (chassi.length < 7 || chassi.length > 20) {
      return false;
    }
    //veículos antigos não tem o padrão de 17
    // if (chassi.length !== 17) {
    //   return false;
    // }

    // // Não pode conter I, O, Q
    // if (/[IOQ]/.test(chassi)) {
    //   return false;
    // }

    // // Evita chassi com todos os caracteres iguais (ex: 11111111111111111)
    // if (chassi.split("").every((char) => char === chassi[0])) {
    //   return false;
    // }

    return true;
  },

  ValidarRenavam(renavam) {
    if (!renavam) {
      return false;
    }

    renavam = UtilHelper.ApenasNumero(renavam);

    if (renavam.length < 9 || renavam.length > 11) {
      return false;
    }

    // Preenche com zeros à esquerda se necessário
    renavam = renavam.padStart(11, "0");

    return VeiculoHelper.ValidarDigitoRenavam(renavam);
  },

  ValidarDigitoRenavam(renavam) {
    let soma = 0;
    let multiplicador = 2;

    for (let i = renavam.length - 2; i >= 0; i--) {
      soma += parseInt(renavam.charAt(i), 10) * multiplicador;
      multiplicador = multiplicador === 9 ? 2 : multiplicador + 1;
    }

    const resto = soma % 11;
    const digito = resto === 0 || resto === 1 ? 0 : 11 - resto;

    return digito === parseInt(renavam.charAt(renavam.length - 1), 10);
  },

  /**
   * Envia o arquivo CRLV para a Azure Function e obtém os dados processados.
   * @param {File} documento - O arquivo PDF do CRLV.
   * @returns {Promise<Object|null>} Retorna o objeto CRLV ou null se falhar.
   */
  async getDadosCRLV(documento: File): Promise<CRLV | null> {
    try {
      if (!documento) {
        console.warn("Nenhum documento fornecido.");
        return null;
      }

      const formData = new FormData();
      formData.append("documento", documento);

      const response = await functionApi.post<CRLV>(
        "/veiculo/ObterDadosCRLV",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200 && response.data) {
        return response.data;
      }

      console.warn("Resposta inesperada da API", response);
      return null;
    } catch (error) {
      console.error("Erro ao obter dados do CRLV:", error);
      return null;
    }
  },
};
