import axios, { AxiosRequestConfig } from "axios";
import jwtDecode from "jwt-decode";
import { AdminUtil } from "src/util/AdminUtil";
const API_BASE_URL = process.env.REACT_APP_API_BASE;

axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.get["Access-Control-Allow-Origin"] = "*";

const tokenValido = (tokenJWT) => {
  if (!tokenJWT) return false;

  const decodedToken: any = jwtDecode(tokenJWT);
  const currentTime = Date.now() / 1000;
  return decodedToken.exp > currentTime;
};

const getTokenJWT = (validarToken: boolean = true) => {
  const tokenJWT = window.localStorage.getItem("tokenJWT");
  if (!tokenJWT || (validarToken && !tokenValido(tokenJWT))) {
    return null;
  }
  return tokenJWT;
};
const limparSessao = () => {
  localStorage.removeItem("tokenJWT");
  localStorage.setItem("TotalNotificacao", "0");
};
const podeRenovarTokenJWT = () => {
  const tokenJWT = window.localStorage.getItem("tokenJWT");
  if (!tokenJWT) return false;

  const decodedToken: any = jwtDecode(tokenJWT);
  const currentTime = Date.now() / 1000;

  // Verifica se o token ainda está válido
  if (decodedToken.exp > currentTime) {
    return true;
  }

  // Verifica se o token expirou a menos de 24 horas
  const timeSinceExpiration = currentTime - decodedToken.exp;
  const twentyFourHoursInSeconds = 24 * 60 * 60; // 86400 segundos

  return timeSinceExpiration <= twentyFourHoursInSeconds;
};

const getUsuarioSessao = (validarToken: boolean = true) => {
  const tokenJWT = getTokenJWT(validarToken);
  if (!tokenJWT) {
    return null;
  }
  const decodedToken: any = jwtDecode(tokenJWT);

  const toCamelCase = (str) => {
    return str.charAt(0).toLowerCase() + str.slice(1);
  };
  const dadosUsuario = JSON.parse(decodedToken.usuario);
  const userDataCamelCase = {};
  for (const key in dadosUsuario) {
    if (dadosUsuario.hasOwnProperty(key)) {
      userDataCamelCase[toCamelCase(key)] = dadosUsuario[key];
    }
  }
  return userDataCamelCase;
};
const getUsuarioVersao = (validarToken: boolean = true) => {
  const tokenJWT = getTokenJWT();
  if (!tokenJWT) {
    return null;
  }
  const decodedToken: any = jwtDecode(tokenJWT);
  return decodedToken.versao;
};
const salvarSessao = (tokenJWT) => {
  if (tokenJWT) {
    localStorage.setItem("tokenJWT", tokenJWT); //alterar para cookie http only
  } else {
    localStorage.removeItem("tokenJWT");
  }
};
const obterResponse = (response: any) => {
  return !(
    Object.hasOwn(response, "data") &&
    response.request &&
    response.headers
  )
    ? response
    : response.data;
};
const gomelius = {
  autenticarUsuario: (email, senha) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_BASE_URL}Auth/AutenticarUsuario`, {
          email: email,
          senha: senha,
        })
        .then((response: any) => {
          const usuarioId = response.usuarioId;
          const chavePreAutorizacao = response.chavePreAutorizacao;
          if (!response.suporte) {
            axios
              .post(`${API_BASE_URL}Auth/ListaClienteUsuario`, {
                usuarioId: usuarioId,
                chavePreAutorizacao: chavePreAutorizacao,
              })
              .then((response: any) => {
                if (response.length === 1) {
                  const clienteSistema = response[0];

                  gomelius
                    .autenticarUsuarioCliente(
                      usuarioId,
                      chavePreAutorizacao,
                      clienteSistema
                    )
                    .then(function (dados: any) {
                      localStorage.setItem(
                        "fusoHorario",
                        dados.usuario.fusoHorario
                      );
                      resolve({
                        selecionarEmpresa: false,
                        selecionarCliente: false,
                        usuario: dados.usuario,
                        versao: dados.versao,
                      });
                    })
                    .catch(function (error) {
                      resolve(error);
                    });
                } else {
                  resolve({
                    selecionarEmpresa: true,
                    usuario: null,
                    usuarioId: usuarioId,
                    versao: null,
                    chavePreAutorizacao: chavePreAutorizacao,
                    lista: response,
                  });
                }
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            axios
              .post(`${API_BASE_URL}Auth/ListaCliente`, {
                usuarioId: usuarioId,
                chavePreAutorizacao: chavePreAutorizacao,
              })
              .then((response: any) => {
                resolve({
                  selecionarCliente: true,
                  usuario: null,
                  usuarioId: usuarioId,
                  versao: null,
                  chavePreAutorizacao: chavePreAutorizacao,
                  lista: response,
                });
              })
              .catch((error) => {
                reject(error);
              });
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  usuarioAdministrador: () => {
    const tokenJWT = window.localStorage.getItem("tokenJWT");
    if (!tokenJWT || !tokenValido(tokenJWT)) {
      return false;
    }

    try {
      const decodedToken: any = jwtDecode(tokenJWT);
      return (decodedToken.administrador || "").toLowerCase() === "true";
    } catch (error) {
      console.error("Erro ao decodificar o token JWT:", error);
      return false;
    }
  },
  obterPermissoes: () => {
    const tokenJWT = window.localStorage.getItem("tokenJWT");

    if (!tokenJWT || !tokenValido(tokenJWT)) {
      return [];
    }

    try {
      // Decodifica o token JWT e aplica o tipo 'any'
      const decodedToken: any = jwtDecode(tokenJWT);

      // Verifica se o claim 'permissoes' existe
      const permissoesHex = decodedToken.sessao;
      if (!permissoesHex) {
        return [];
      }

      // Função para converter um valor hexadecimal para um array de permissões
      const decodePermissionsFromHex = (hex: string): number[] => {
        const permissoes: number[] = [];
        const bitArray = [];

        // Converte a string hexadecimal em um array de bytes
        for (let i = 0; i < hex.length; i += 2) {
          const byte = parseInt(hex.substring(i, i + 2), 16);
          bitArray.push(byte);
        }

        // Percorre o array de bytes e verifica os bits
        bitArray.forEach((byte, byteIndex) => {
          for (let bitIndex = 0; bitIndex < 8; bitIndex++) {
            const bitPosition = byteIndex * 8 + bitIndex;

            // Verifica se o bit está ativo
            if (byte & (1 << bitIndex)) {
              permissoes.push(bitPosition); // Adiciona a posição do bit como permissão
            }
          }
        });

        return permissoes; // Retorna as permissões como um array de números
      };

      // Decodifica as permissões da string hexadecimal
      const permissoes = decodePermissionsFromHex(permissoesHex);

      // Retorna o array de permissões decodificadas
      return permissoes;
    } catch (error) {
      console.error("Erro ao decodificar o token JWT ou as permissões:", error);
      return [];
    }
  },
  autenticarUsuarioCliente: (
    usuarioId,
    chavePreAutorizacao,
    clienteSistema,
    usuarioSuporteId = null
  ) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${API_BASE_URL}Auth/AutenticarUsuarioCliente${
            usuarioSuporteId ? `/${usuarioSuporteId}` : ""
          }`,
          {
            id: clienteSistema.id,
            usuarioId: usuarioId,
            clienteId: clienteSistema.clienteId,
            chavePreAutorizacao: chavePreAutorizacao,
          }
        )
        .then((response: any) => {
          salvarSessao(response.token);
          const decodedToken: any = jwtDecode(response.token);

          gomelius
            .obter("Usuario/Listafavorito")
            .then((lista: any) => {
              AdminUtil.salvarListaFavorito(lista);
            })
            .catch(() => {
              AdminUtil.salvarListaFavorito([]);
            });

          gomelius
            .obter("Usuario/ObterConfiguracao")
            .then((configuracao: any) => {
              AdminUtil.salvarConfiguracao(configuracao);
              resolve({
                usuario: response.usuario,
                versao: decodedToken.versao,
              });
            })
            .catch(function () {
              resolve({
                usuario: response.usuario,
                versao: decodedToken.versao,
              });
            });

          gomelius
            .obter("Notificacao/TotalNaoLido")
            .then((resp: any) => {
              localStorage.setItem("TotalNotificacao", resp.data ?? 0);
            })
            .catch(() => {
              localStorage.setItem("TotalNotificacao", "0");
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  atualizarToken: () => {
    return new Promise((resolve, reject) => {
      const url = `${API_BASE_URL}Auth/AtualizarToken`;
      const config = {
        headers: { Authorization: `Bearer ${getTokenJWT()}` },
      };
      axios
        .post(url, null, config)
        .then((response: any) => {
          const decodedToken: any = jwtDecode(response.token);
          salvarSessao(response.token);
          resolve({ usuario: response.usuario, versao: decodedToken.versao });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  renovarToken: (senha) => {
    return new Promise((resolve, reject) => {
      const usuario: any = getUsuarioSessao(false);
      const token: any = getTokenJWT(false);
      if (usuario && token) {
        axios
          .post(`${API_BASE_URL}Auth/RenovarToken`, {
            usuarioId: usuario.id,
            clienteId: usuario.clienteId,
            email: usuario.email,
            senha: senha,
            token: token,
          })
          .then((response: any) => {
            salvarSessao(response.token);
            const decodedToken: any = jwtDecode(response.token);

            gomelius
              .obter("Usuario/Listafavorito")
              .then((lista: any) => {
                AdminUtil.salvarListaFavorito(lista);
              })
              .catch(() => {
                AdminUtil.salvarListaFavorito([]);
              });

            gomelius
              .obter("Usuario/ObterConfiguracao")
              .then((configuracao) => {
                AdminUtil.salvarConfiguracao(configuracao);
                resolve({
                  usuario: response.usuario,
                  versao: decodedToken.versao,
                });
              })
              .catch(function (error) {
                resolve({
                  usuario: response.usuario,
                  versao: decodedToken.versao,
                });
              });
            gomelius
              .obter("Notificacao/TotalNaoLido")
              .then((resp: any) => {
                localStorage.setItem("TotalNotificacao", resp.data ?? 0);
              })
              .catch(() => {
                localStorage.setItem("TotalNotificacao", "0");
              });
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        reject("Não tem usuário na sessão, deverá realizar o login.");
      }
    });
  },
  getToken: () => {
    const token = getTokenJWT();
    return token;
  },
  limparSessao: () => {
    limparSessao();
  },
  obterUsuarioAutenticado: (validarToken: boolean = true) => {
    return new Promise((resolve, reject) => {
      const tokenJWT = getTokenJWT(validarToken);
      if (tokenJWT) {
        const usuario = getUsuarioSessao(validarToken);
        const versao = getUsuarioVersao(validarToken);
        resolve({ usuario: usuario, versao: versao });
      } else {
        reject({ renovarSessao: podeRenovarTokenJWT() });
      }
    });
  },
  logout: () => {
    salvarSessao(null);
  },
  obterRegistro: (id, endPoint) => {
    const config = {
      headers: { Authorization: `Bearer ${getTokenJWT()}` },
    };
    return new Promise((resolve, reject) => {
      axios
        .get(`${API_BASE_URL}${endPoint}/${id}`, config)
        .then((response: any) => {
          resolve(obterResponse(response));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  obterOK: (endPoint: string): Promise<void> => {
    const config: any = {
      headers: { Authorization: `Bearer ${getTokenJWT()}` },
    };

    return new Promise<void>((resolve, reject) => {
      axios
        .get(`${API_BASE_URL}${endPoint}`, config)
        .then(() => {
          resolve(); // Resolve a promise sem retornar valor
        })
        .catch(() => {
          reject(); // Rejeita a promise sem retornar valor
        });
    });
  },
  obterBoolean: (endPoint) => {
    const config = {
      headers: { Authorization: `Bearer ${getTokenJWT()}` },
    };
    return new Promise((resolve, reject) => {
      axios
        .get(`${API_BASE_URL}${endPoint}`, config)
        .then((response: any) => {
          resolve(Boolean(obterResponse(response)));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  obter: (endPoint) => {
    const config = {
      headers: { Authorization: `Bearer ${getTokenJWT()}` },
    };
    return new Promise((resolve, reject) => {
      axios
        .get(`${API_BASE_URL}${endPoint}`, config)
        .then((response: any) => {
          resolve(obterResponse(response));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  obterTexto: (endPoint) => {
    const config: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${getTokenJWT()}` },

      responseType: "text",
    };

    return new Promise((resolve, reject) => {
      axios
        .get(`${API_BASE_URL}${endPoint}`, config)
        .then((response) => {
          resolve(obterResponse(response));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  obterChildren: (id, childrenId, endPoint) => {
    const config = {
      headers: { Authorization: `Bearer ${getTokenJWT()}` },
    };
    return new Promise((resolve, reject) => {
      axios
        .get(`${API_BASE_URL}${endPoint}/${id}/${childrenId}`, config)
        .then((response: any) => {
          resolve(obterResponse(response));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  post: (data, endPoint, contentType = "application/json") => {
    const config = {
      headers: {
        Authorization: `Bearer ${getTokenJWT()}`,
        "Content-Type": contentType,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_BASE_URL}${endPoint}`, data, config)
        .then((response: any) => {
          resolve(obterResponse(response));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  postChildren: (id, data, endPoint, contentType = "application/json") => {
    const config = {
      headers: {
        Authorization: `Bearer ${getTokenJWT()}`,
        "Content-Type": contentType,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_BASE_URL}${endPoint}/${id}`, data, config)
        .then((response: any) => {
          resolve(obterResponse(response));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  postChildrenForm: (
    id,
    data,
    endPoint,
    contentType = "multipart/form-data"
  ) => {
    const config = {
      headers: {
        Authorization: `Bearer ${getTokenJWT()}`,
        "Content-Type": contentType,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_BASE_URL}${endPoint}/${id}`, data, config)
        .then((response: any) => {
          resolve(obterResponse(response));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  postFile: (
    file,
    fieldName,
    endPoint,
    contentType = "multipart/form-data"
  ) => {
    const config = {
      headers: {
        Authorization: `Bearer ${getTokenJWT()}`,
        "Content-Type": contentType,
      },
    };
    const formData = new FormData();
    formData.append(fieldName, file);
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_BASE_URL}${endPoint}`, formData, config)
        .then((response: any) => {
          resolve(obterResponse(response));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  postFileWithData: (
    data,
    file,
    fieldName,
    endPoint,
    contentType = "multipart/form-data"
  ) => {
    const config = {
      headers: {
        Authorization: `Bearer ${getTokenJWT()}`,
        "Content-Type": contentType,
      },
    };

    const formData = new FormData();

    // Adiciona o arquivo
    formData.append(fieldName, file);

    // Adiciona os dados extras (serializados como JSON)
    for (const key in data) {
      formData.append(key, data[key]);
    }

    return new Promise((resolve, reject) => {
      axios
        .post(`${API_BASE_URL}${endPoint}`, formData, config)
        .then((response: any) => {
          resolve(obterResponse(response));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  put: (id, data, endPoint, contentType = "application/json") => {
    const config = {
      headers: {
        Authorization: `Bearer ${getTokenJWT()}`,
        "Content-Type": contentType,
      },
    };
    const url = id
      ? `${API_BASE_URL}${endPoint}/${id}`
      : `${API_BASE_URL}${endPoint}`;
    return new Promise((resolve, reject) => {
      axios
        .put(url, data, config)
        .then((response: any) => {
          resolve(obterResponse(response));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  putChildren: (
    id,
    childrenId,
    data,
    endPoint,
    contentType = "application/json"
  ) => {
    const config = {
      headers: {
        Authorization: `Bearer ${getTokenJWT()}`,
        "Content-Type": contentType,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put(`${API_BASE_URL}${endPoint}/${id}/${childrenId}`, data, config)
        .then((response: any) => {
          resolve(obterResponse(response));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  delete: (id, endPoint) => {
    const config = {
      headers: { Authorization: `Bearer ${getTokenJWT()}` },
    };
    return new Promise((resolve, reject) => {
      axios
        .delete(`${API_BASE_URL}${endPoint}/${id}`, config)
        .then((response: any) => {
          resolve(obterResponse(response));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteChildren: (id, childrenId, endPoint) => {
    const config = {
      headers: { Authorization: `Bearer ${getTokenJWT()}` },
    };
    return new Promise((resolve, reject) => {
      axios
        .delete(`${API_BASE_URL}${endPoint}/${id}/${childrenId}`, config)
        .then((response: any) => {
          resolve(obterResponse(response));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  obterListaFiltro: (form, endPoint, lista = false, useHTTPPOST = false) => {
    function addQueryString(url, params) {
      if (url.includes("?")) {
        return `${url}&${params}`;
      } else {
        return `${url}?${params}`;
      }
    }
    function serializeForm(obj) {
      const partes = [];
      for (const chave in obj) {
        if (
          obj.hasOwnProperty(chave) &&
          obj[chave] !== null &&
          obj[chave] !== undefined &&
          obj[chave] !== ""
        ) {
          partes.push(
            `${encodeURIComponent(chave)}=${encodeURIComponent(obj[chave])}`
          );
        }
      }
      return partes.join("&");
    }
    return new Promise((resolve, reject) => {
      const url = `${API_BASE_URL}${endPoint}`;
      const config = {
        headers: { Authorization: `Bearer ${getTokenJWT()}` },
      };
      if (!useHTTPPOST) {
        let urlRequest = url;
        if (form) {
          const queryForm = serializeForm(form);
          urlRequest = addQueryString(url, queryForm);
        }

        axios
          .get(urlRequest, config)
          .then((response: any) => {
            if (!lista) {
              resolve(obterResponse(response));
            } else {
              var data = obterResponse(response);
              resolve(data.lista);
            }
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        axios
          .post(url, form, config)
          .then((response: any) => {
            if (!lista) {
              resolve(obterResponse(response));
            } else {
              var data = obterResponse(response);
              resolve(data.lista);
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  },
  obterLista: (endPoint) => {
    return new Promise((resolve, reject) => {
      const url = `${API_BASE_URL}${endPoint}`;
      const config = {
        headers: { Authorization: `Bearer ${getTokenJWT()}` },
      };
      axios
        .get(url, config)
        .then((response: any) => {
          resolve(obterResponse(response));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  obterListaSubCadastro: (id, endPoint) => {
    return new Promise((resolve, reject) => {
      const url = `${API_BASE_URL}${endPoint}/${id}`;
      const config = {
        headers: { Authorization: `Bearer ${getTokenJWT()}` },
      };
      axios
        .get(url, config)
        .then((response: any) => {
          resolve(obterResponse(response));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  obterConfiguracao: (nome, tipo = "string") => {
    const config = {
      headers: { Authorization: `Bearer ${getTokenJWT()}` },
    };

    return new Promise((resolve, reject) => {
      axios
        .get(`${API_BASE_URL}Configuracao/Obter/${nome}/${tipo}`, config)
        .then((response) => resolve(obterResponse(response)))
        .catch((error) => reject(error));
    });
  },

  obterConfiguracoes: (colunas) => {
    const config = {
      headers: { Authorization: `Bearer ${getTokenJWT()}` },
    };

    return new Promise((resolve, reject) => {
      axios
        .post(`${API_BASE_URL}Configuracao/ObterLista`, colunas, config)
        .then((response) => resolve(obterResponse(response)))
        .catch((error) => reject(error));
    });
  },
};

export default gomelius;
