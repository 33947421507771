import React, { useState, useEffect, useRef } from "react";
import { Icon } from "@ailibs/feather-react-ts";
import "./Search.css"; // Importe os estilos CSS
import gomelius from "src/api/gomelius";
import { Util } from "src/util";
import { CNPJ, CPF, TELEFONE } from "src/constants";
import { entidadePessoaEnum, getEnum } from "src/enums";

const Search = (props) => {
  const [isSearch, setSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [pessoaList, setPessoaList] = useState([]);
  const inputRef = useRef(null); // Ref para o input

  useEffect(() => {
    // Definir o foco no input quando o componente for renderizado
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [isSearch]); // Executado apenas na montagem do componente

  const handleInputChange = async (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    // Verifica se a consulta possui pelo menos 3 caracteres antes de pesquisar
    if (query.length >= 2) {
      fetchPessoas(query);
    } else {
      setPessoaList([]); // Limpa a lista de pessoaes se a consulta for menor que 3 caracteres
    }
  };

  const fetchPessoas = async (query: string) => {
    try {
      const lista: any = await gomelius.obterLista(
        `Pessoa/PesquisaRapida/${query}`
      );
      setPessoaList(lista);
    } catch (error) {
      console.error("Erro ao buscar pesso:", error);
    }
  };

  const handleResultClick = (id) => {
    // Redireciona para a rota /CRM/:id ao clicar em um resultado da pesquisa
    window.location.href = `/CRM/${id}`;
    setSearch(false); // Fecha o dropdown de resultados ao clicar
    setSearchQuery(""); // Limpa o campo de pesquisa
    setPessoaList([]); // Limpa a lista de resultados
  };

  const highlightText = (text, query) => {
    // Verifica se o texto contém a consulta e destaca o trecho correspondente
    const regex = new RegExp(`(${query})`, "gi");
    return text.replace(regex, "<strong>$1</strong>");
  };

  return (
    <>
      <div className="dropdown d-inline-block d-none d-lg-block">
        <button
          type="button"
          className="btn header-item"
          id="page-header-search-dropdown"
          onClick={() => setSearch(!isSearch)}
        >
          <Icon name="search" className="icon-sm" />
        </button>
        <div
          className={
            isSearch
              ? "dropdown-menu dropdown-menu-lg search dropdown-menu-end p-0 show"
              : "dropdown-menu dropdown-menu-lg search dropdown-menu-end p-0"
          }
          aria-labelledby="page-header-search-dropdown"
        >
          <form className="p-2">
            <div className="search-box">
              <div className="position-relative">
                <input
                  ref={inputRef} // Referência para o input
                  type="text"
                  className="form-control rounded bg-light border-0"
                  placeholder="Digite o nome para pesquisar..."
                  value={searchQuery}
                  onChange={handleInputChange}
                />
                <i className="mdi mdi-magnify search-icon"></i>
              </div>
            </div>
          </form>
          {pessoaList.length > 0 && (
            <div className="search-results">
              <ul>
                {pessoaList.map((pessoa) => {
                  const tipoAssociacoes = [
                    { chave: "cliente", tipo: 1 },
                    { chave: "fornecedor", tipo: 2 },
                    { chave: "transportador", tipo: 3 },
                    { chave: "engenheiro", tipo: 4 },
                    { chave: "motorista", tipo: 5 },
                    { chave: "funcionario", tipo: 6 },
                    { chave: "supervisor", tipo: 7 },
                    { chave: "agente", tipo: 8 },
                    { chave: "vendedor", tipo: 9 },
                  ];

                  const tipos = tipoAssociacoes
                    .filter((associacao) => pessoa[associacao.chave])
                    .map((associacao) => {
                      const badge = getEnum(
                        entidadePessoaEnum,
                        associacao.tipo
                      );
                      return (
                        <span
                          key={associacao.tipo}
                          className={`badge rounded-pill ${badge.badge} ms-2`}
                        >
                          {badge.descricao}
                        </span>
                      );
                    });

                  return (
                    <li
                      className={pessoa.situacao === 2 ? "result-inactive" : ""}
                      key={pessoa.id}
                      onClick={() => handleResultClick(pessoa.id)}
                    >
                      <div className="nome-container">
                        <span
                          className="nome"
                          dangerouslySetInnerHTML={{
                            __html: highlightText(pessoa.nome, searchQuery),
                          }}
                        />
                        {/* Badges logo abaixo do nome */}
                        <div className="badges-container">{tipos}</div>
                      </div>

                      {pessoa.numeroDocumento && (
                        <small className="numero-documento d-flex align-items-center mt-1">
                          <i className="mdi mdi-card-account-details-outline me-1" />
                          {Util.applyMask(
                            pessoa.numeroDocumento,
                            pessoa.tipo === 1 ? CPF : CNPJ
                          )}
                        </small>
                      )}

                      {pessoa.telefone && (
                        <small className="telefone d-flex align-items-center mt-1">
                          <i className="mdi mdi-phone-outline me-1" />
                          {Util.applyMask(pessoa.telefone, TELEFONE)}
                        </small>
                      )}

                      {pessoa.email && (
                        <small className="email d-flex align-items-center mt-1">
                          <i className="mdi mdi-email-outline me-1" />
                          <span
                            dangerouslySetInnerHTML={{
                              __html: highlightText(pessoa.email, searchQuery),
                            }}
                          />
                        </small>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Search;
