// Tipagem para o retorno de Carreta (VeiculoReboque)
export interface VeiculoReboque {
  identificador: string;
  anoFabricacao: number;
  caracteristica: number;
  tipoEixo: string;
  nacional: boolean;
  placa: string;
  marca: string;
  modelo: string;
  tipoCarroceria: string;
  chassi: string;
  renavam: string;
  rntrc: string;
  tara: number;
  quantidadeEixo: number;
  distanciaEntreEixo: number;
  quantidadePneusEixo: number;
}

// Tipagem para o retorno de Trator (VeiculoTrator)
export interface VeiculoTrator {
  identificador: string;
  direcao: string; // Você pode criar enums depois, se quiser!
  tracao: string; // Você pode criar enums depois, se quiser!
  cmt: number;
  potencia: number;
  anoFabricacao: number;
  caracteristica: number;
  tipoEixo: string;
  nacional: boolean;
  placa: string;
  bidirecional: boolean;
  marca: string;
  modelo: string;
  tipoCarroceria: string;
  chassi: string;
  renavam: string;
  rntrc: string;
  tara: number;
}

const BASE_URL = "https://aetmasterfunction.azurewebsites.net/api/dnit/";

export const DNITHelper = {
  async getCarretaPorPlaca(placa: string): Promise<VeiculoReboque | null> {
    try {
      const response = await fetch(`${BASE_URL}carreta/placa/${placa}`);
      if (!response.ok) {
        console.warn(`Carreta não encontrada com a placa ${placa}`);
        return null;
      }
      const data: VeiculoReboque = await response.json();
      return data;
    } catch (error) {
      console.error("Erro ao buscar carreta por placa:", error);
      throw error;
    }
  },

  async getCarretaPorChassi(chassi: string): Promise<VeiculoReboque | null> {
    try {
      const response = await fetch(`${BASE_URL}carreta/chassi/${chassi}`);
      if (!response.ok) {
        console.warn(`Carreta não encontrada com o chassi ${chassi}`);
        return null;
      }
      const data: VeiculoReboque = await response.json();
      return data;
    } catch (error) {
      console.error("Erro ao buscar carreta por chassi:", error);
      throw error;
    }
  },

  async getTratorPorPlaca(placa: string): Promise<VeiculoTrator | null> {
    try {
      const response = await fetch(`${BASE_URL}trator/placa/${placa}`);
      if (!response.ok) {
        console.warn(`Trator não encontrado com a placa ${placa}`);
        return null;
      }
      const data: VeiculoTrator = await response.json();
      return data;
    } catch (error) {
      console.error("Erro ao buscar trator por placa:", error);
      throw error;
    }
  },

  async getTratorPorChassi(chassi: string): Promise<VeiculoTrator | null> {
    try {
      const response = await fetch(`${BASE_URL}trator/chassi/${chassi}`);
      if (!response.ok) {
        console.warn(`Trator não encontrado com o chassi ${chassi}`);
        return null;
      }
      const data: VeiculoTrator = await response.json();
      return data;
    } catch (error) {
      console.error("Erro ao buscar trator por chassi:", error);
      throw error;
    }
  },
};
