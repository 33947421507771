import axios from "axios";
import { UtilHelper } from "./UtilHelper";
import gomelius from "src/api/gomelius";

export interface DadosEmpresa {
  status: "OK" | "ERROR";
  message?: string;

  abertura?: string;
  situacao?: string;
  tipo?: string;
  razaoSocial?: string;
  fantasia?: string;
  porte?: string;
  natureza_juridica?: string;
  logradouro?: string;
  numero?: string;
  municipio?: string;
  bairro?: string;
  uf?: string;
  cep?: string;
  email?: string;
  telefone?: string;
  data_situacao?: string;
  motivo_situacao?: string;
  cnpj?: string;
  ultima_atualizacao?: string;
  complemento?: string;
  efr?: string;
}
const tratarTelefone = (telefone?: string): string => {
  if (!telefone) return "";

  // Divide pelo separador, pega o primeiro
  const partes = telefone.split(/[\/\\]/); // regex pega / ou \
  const primeiroTelefone = partes[0].trim(); // tira espaços extras

  // Deixa só números
  return UtilHelper.ApenasNumero(primeiroTelefone);
};
const functionApi = axios.create({
  baseURL: "https://aetmasterfunction.azurewebsites.net/api",
  timeout: 10000, // opcional: tempo limite em ms
  headers: {},
});
export const PessoaHelper = {
  async obterDadosEmpresa(cnpj: string): Promise<DadosEmpresa | null> {
    try {
      if (!cnpj || typeof cnpj !== "string") {
        console.warn("CNPJ inválido");
        return null;
      }

      const cnpjLimpo = UtilHelper.ApenasNumero(cnpj);

      if (cnpjLimpo.length !== 14) {
        console.warn("CNPJ deve conter 14 dígitos");
        return null;
      }

      const url = `https://aetmasterfunction.azurewebsites.net/api/empresa/${cnpjLimpo}`;

      // Agora usando axios ao invés de fetch
      const response = await functionApi.get<DadosEmpresa>(url, {
        headers: {
          Accept: "application/json",
        },
      });

      const data = response.data;

      if (data.status !== "OK") {
        console.warn(
          `Empresa não encontrada: ${data.message || "Sem motivo especificado"}`
        );
        return null;
      }

      // Trata o telefone antes de retornar
      data.telefone = tratarTelefone(data.telefone);

      return data;
    } catch (error: any) {
      console.error(
        `Erro ao consultar dados da empresa: ${error.message || error}`
      );
      return null;
    }
  },

  async cadastrarEmpresaSimplificada(
    cnpj: string,
    nome: string
  ): Promise<string | null> {
    try {
      if (!cnpj || !nome) {
        console.warn("CNPJ e Nome são obrigatórios");
        return null;
      }

      const cnpjLimpo = UtilHelper.ApenasNumero(cnpj);

      if (cnpjLimpo.length !== 14) {
        console.warn("CNPJ deve conter 14 dígitos");
        return null;
      }

      // Busca dados complementares
      const dados = await PessoaHelper.obterDadosEmpresa(cnpjLimpo);

      // Preenche o objeto de cadastro simplificado
      const formulario = {
        id: "",
        numeroDocumento: cnpjLimpo,
        nome: nome || dados?.razaoSocial || "",
        tipo: 2,
        telefone: dados?.telefone || "",
        email: dados?.email || "",
        nomeContato: "",

        cliente: false,
        fornecedor: false,
        transportador: true,
        engenheiro: false,
        motorista: false,
        funcionario: false,
        supervisor: false,
        agente: false,
        vendedor: false,

        logradouro: dados?.logradouro || "",
        numero: dados?.numero || "",
        bairro: dados?.bairro || "",
        complemento: dados?.complemento || "",
        cidade: dados?.municipio || "",
        estado: dados?.uf || "",
        cep: dados?.cep ? UtilHelper.ApenasNumero(dados.cep) : "",

        logradouroCorrespondencia: "",
        numeroCorrespondencia: "",
        bairroCorrespondencia: "",
        complementoCorrespondencia: "",
        cidadeCorrespondencia: "",
        estadoCorrespondencia: "",
        cepCorrespondencia: "",
      };

      const resultado: any = await gomelius.post(formulario, "Pessoa");
      if (resultado.sucesso) {
        return resultado.id;
      }
      return null;
    } catch (error: any) {
      console.error(
        `Erro ao cadastrar empresa simplificada: ${error.message || error}`
      );
      return null;
    }
  },

  async cadastrarPessoaFisicaSimplificada(
    cpf: string,
    nome: string
  ): Promise<string | null> {
    try {
      if (!cpf || !nome) {
        console.warn("CPF e Nome são obrigatórios");
        return null;
      }

      const numeroDocumentoLimpo = UtilHelper.ApenasNumero(cpf);

      if (numeroDocumentoLimpo.length !== 11) {
        console.warn("CPF deve conter 11 dígitos");
        return null;
      }
      // Preenche o objeto de cadastro simplificado
      const formulario = {
        id: "",
        numeroDocumento: numeroDocumentoLimpo,
        nome: nome,
        tipo: 1,
        nomeContato: "",

        cliente: false,
        fornecedor: false,
        transportador: true,
        engenheiro: false,
        motorista: false,
        funcionario: false,
        supervisor: false,
        agente: false,
        vendedor: false,

        logradouro: "",
        numero: "",
        bairro: "",
        complemento: "",
        cidade: "",
        estado: "",
        cep: "",

        logradouroCorrespondencia: "",
        numeroCorrespondencia: "",
        bairroCorrespondencia: "",
        complementoCorrespondencia: "",
        cidadeCorrespondencia: "",
        estadoCorrespondencia: "",
        cepCorrespondencia: "",
      };

      const resultado: any = await gomelius.post(formulario, "Pessoa");
      if (resultado.sucesso) {
        return resultado.id;
      }
      return null;
    } catch (error: any) {
      console.error(
        `Erro ao cadastrar pessoa física simplificada: ${
          error.message || error
        }`
      );
      return null;
    }
  },
};
