export const UtilHelper = {
  ApenasNumero(valor) {
    if (!valor || typeof valor !== "string") {
      return "";
    }

    // Remove tudo que não for número usando regex
    return valor.replace(/\D/g, "");
  },
};
