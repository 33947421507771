import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import VerticalLayout from "./VerticalLayout";
import HorizontalLayout from "./HorizontalLayout/index";
import NotificacaoHub from "./organisms/NotificacaoHub";
import { DateHelper } from "src/helpers";
import gomelius from "src/api/gomelius";

function getLayout(layoutType: any) {
  switch (layoutType) {
    case "horizontal":
      return HorizontalLayout;
    default:
      return VerticalLayout;
  }
}

const LayoutAutenticado = () => {
  const selectLayoutProperties = createSelector(
    (state: any) => state.Layout,
    (layout: any) => ({
      layoutType: layout.layoutType,
    })
  );

  const { layoutType } = useSelector(selectLayoutProperties);
  const Layout = getLayout(layoutType);

  // Função para registrar o Service Worker e solicitar permissão de notificação
  useEffect(() => {
    async function registerServiceWorkerAndRequestPermission() {
      if (!("serviceWorker" in navigator) || !("Notification" in window)) {
        console.warn(
          "Service Workers ou Notificações não são suportados neste navegador."
        );
        return;
      }

      try {
        // Solicitar permissão para notificações, se ainda não concedida
        if (Notification.permission !== "granted") {
          const permission = await Notification.requestPermission();
          if (permission !== "granted") {
            console.warn("Permissão para notificações negada.");
            return;
          }
        }

        // Registra o Service Worker e garante que ele está ativo
        const registration = await navigator.serviceWorker.register(
          "/service-worker.js"
        );
        const activeRegistration = await navigator.serviceWorker.ready;

        // Inscrever usuário para notificações push
        await subscribeUserToPush(activeRegistration);
      } catch (error) {
        console.error(
          "Erro ao registrar o Service Worker ou solicitar permissão:",
          error
        );
      }
    }

    // Função para criar a inscrição do usuário para notificações push
    async function subscribeUserToPush(
      registration: ServiceWorkerRegistration
    ) {
      try {
        const applicationServerKey = process.env.REACT_APP_PUSHNOTIFICATION_KEY;
        if (!applicationServerKey) {
          console.error(
            "Chave pública VAPID não está definida. Verifique o .env."
          );
          return;
        }

        // Verificar assinatura existente para evitar duplicações
        const existingSubscription =
          await registration.pushManager.getSubscription();
        if (existingSubscription) {
          return;
        }

        // Criar nova assinatura
        const subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(applicationServerKey),
        });

        // Enviar os dados da assinatura ao servidor
        const { endpoint, expirationTime, keys } = subscription.toJSON();
        const subscricaoNotificacao = {
          Endpoint: endpoint,
          ChavePublica: keys.p256dh,
          ChaveAuth: keys.auth,
          DataExpiracao: expirationTime
            ? DateHelper.formatDate(new Date(expirationTime))
            : null,
        };

        await gomelius.post(subscricaoNotificacao, "SubscricaoNotificacao");
      } catch (error) {
        console.error("Erro ao criar a subscrição de notificações:", error);
      }
    }

    registerServiceWorkerAndRequestPermission();
  }, []);

  return (
    <React.Fragment>
      <NotificacaoHub />
      <Layout>
        <Outlet />
      </Layout>
    </React.Fragment>
  );
};

// Função auxiliar para converter a chave VAPID de base64 para Uint8Array
function urlBase64ToUint8Array(base64String: any) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export default LayoutAutenticado;
