import classNames from "classnames";
import { mapToCssModules } from "src/SmartR/Utils/utils";
import { Icon } from "@ailibs/feather-react-ts";
import { Tooltip, TooltipPosition } from "src/components";

interface BotaoCadeadoProps {
  classNameTrancado?: string;
  classNameAberto?: string;
  onClickHandle?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  toolTipTrancado?: string;
  toolTipAberto?: string;
  fechado: boolean;
}

export function BotaoCadeado({
  fechado,
  classNameTrancado,
  classNameAberto,
  onClickHandle,
  toolTipAberto,
  toolTipTrancado,
}: BotaoCadeadoProps) {
  const tooltipText = fechado ? toolTipTrancado : toolTipAberto;

  const classes = mapToCssModules(
    classNames(
      fechado ? classNameTrancado : classNameAberto,
      "btn btn-sm btn-cadeado"
    )
  );

  const content = (
    <button onClick={onClickHandle} className={classes} type="button">
      <Icon name={fechado ? "lock" : "unlock"} className="icon-md" />
    </button>
  );

  return tooltipText ? (
    <Tooltip position={TooltipPosition.Left} text={tooltipText}>
      {content}
    </Tooltip>
  ) : (
    content
  );
}
